<template>
    <div class="box">
        <el-form ref="form" :model="form" label-width="100px">
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="7">
                <el-form-item label="店铺名称">
                  <el-input v-model="form.businessName"></el-input>
               </el-form-item>  
            </el-col>
            <el-col :span="7">
                <el-form-item label="店铺账户">
                  <el-input v-model="form.businessAccount"></el-input>
               </el-form-item>  
            </el-col>
            <el-col :span="7">
                <el-form-item label="店铺密码">
                  <el-input v-model="form.businessPassword"></el-input>
               </el-form-item>  
            </el-col>
           
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="7">
                <el-form-item label="店铺分类">
                  <el-select v-model="form.industryType" placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.categoryName"
                      :label="item.categoryName"
                      :value="item.categoryName">
                    </el-option>
                  </el-select>
                  <!-- <el-input v-model="form.industryType"></el-input> -->
               </el-form-item>  
            </el-col>
           
            <el-col :span="7">
                <el-form-item label="店铺公告">
                  <el-input v-model="form.announcement"></el-input>
               </el-form-item>  
            </el-col>
            <el-col :span="7">
                <el-form-item label="店内联系电话">
                  <el-input v-model="form.servicerPhone"></el-input>
               </el-form-item>  
            </el-col>
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="7">
                <el-form-item label="店铺固定电话">
                  <el-input v-model="form.servicerLandline"></el-input>
               </el-form-item>  
            </el-col>
            <el-col :span="7">
                <el-form-item label="负责人联系电话">
                  <el-input v-model="form.headPhone"></el-input>
               </el-form-item>  
            </el-col>
            
            <el-col :span="7">
                <el-form-item label="法人手机号">
                  <el-input v-model="form.legalerPhone"></el-input>
               </el-form-item>  
            </el-col>
           
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="7">
                <el-form-item label="店铺地址">
                  <el-input v-model="form.address"></el-input>
               </el-form-item>  
            </el-col>
            <el-col :span="7">
                <el-form-item label="连锁店名称">
                  <el-input v-model="form.chainName"></el-input>
               </el-form-item>  
            </el-col>
            <el-col :span="7">
                <el-form-item label="连锁店编码">
                  <el-input v-model="form.chainCode"></el-input>
               </el-form-item>  
            </el-col>
            
           
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            
            <el-col :span="12">
                <el-form-item label="营业时间1">
                  <el-time-picker
                    @change="change"
                    is-range
                    v-model="valuechange"
                    range-separator="至"
                    :start-placeholder="valuechange[0]"
                    :end-placeholder="valuechange[1]"
                    placeholder="选择时间范围"
                    value-format=" HH:mm">
                  </el-time-picker>
                  <!-- <el-date-picker @change="change" v-model="valuechange" type="daterange" start-placeholder="开始日期"
                      end-placeholder="结束日期"  /> -->
               </el-form-item>  
            </el-col>
            <el-col :span="12">
              <el-form-item label="营业时间2">
                  <el-time-picker
                    @change="change1"
                    is-range
                    v-model="valuechange1"
                    range-separator="至"
                    :start-placeholder="valuechange1[0]"
                    :end-placeholder="valuechange1[1]"
                    placeholder="选择时间范围"
                    value-format=" HH:mm"
                    >
                  </el-time-picker>
                  <!-- <el-date-picker @change="change" v-model="valuechange" type="daterange" start-placeholder="开始日期"
                      end-placeholder="结束日期"  /> -->
               </el-form-item>  
              </el-col>
            <el-col :span="7">
                
            </el-col>
            
          </el-row>
          <!-- <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="9">
                <el-form-item label="连锁编码">
                  <el-input v-model="form.chainCode"></el-input>
               </el-form-item>  
            </el-col>
            <el-col :span="9">
                
            </el-col>
            
          </el-row> -->
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="7">
                <el-form-item label="店铺LOGO">
                  <!-- <el-upload
                      class="avatar-uploader xin"
                      :action="$uploadURL"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess1"
                      :before-upload="beforeAvatarUpload"
                    >
                    <div v-if="list1.length > 0" class="flex">
        <div
          style="margin: 5px"
          class="imgBox"
          v-for="(item, index) in list1"
          :key="index"
        >
          <img :src="item" class="avatar" alt="" />
          <div class="img-icon">
            <i
              class="el-icon-zoom-in"
              @click.stop="onPreview(item)"
            ></i>
            <i class="el-icon-delete" @click.stop="handelRemove(1,index)"></i>
          </div>
        </div>
                    </div>
                    <i v-if="list1.length < 1" class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload> -->
                  <div class="list-img-box">
                        <div v-if="list1[0]" class="imgBox">
                          <img class="upload-img" :src="list1[0]" style='width:150px;height:150px' @click="uploadPicture('list1')" alt="店铺LOGO">
                          <div class="img-icon">
                            <i class="el-icon-zoom-in" @click.stop="onPreview(list1[0])"></i>
                            <i class="el-icon-delete" @click.stop="handelRemove(1,index)"></i>
                          </div>
                        </div>
                        <div v-else class="upload-btn" style="" @click="uploadPicture('list1')">
                          <i class="el-icon-plus" style="font-size: 30px;"></i>
                        </div>
                      </div>
                      <div style="font-size: 12px;padding-left: 5px;color: #60627f;">图片建议尺寸(1 × 1)</div>
                     <!-- <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess1"
                        >
                        <img v-if="form.logoUrl" :src="form.logoUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload> -->
               </el-form-item>  
            </el-col>
            <el-col :span="7">
                <el-form-item label="身份证">
                  <!-- <el-upload
                      class="avatar-uploader xin"
                      :action="$uploadURL"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess2"
                      :before-upload="beforeAvatarUpload"
                    >
                    <div v-if="list2.length > 0" class="flex">
        <div
          style="margin: 5px"
          class="imgBox"
          v-for="(item, index) in list2"
          :key="index"
        >
          <img :src="item" class="avatar" alt="" />
          <div class="img-icon">
            <i
              class="el-icon-zoom-in"
              @click.stop="onPreview(item)"
            ></i>
            <i class="el-icon-delete" @click.stop="handelRemove(2,index)"></i>
          </div>
        </div>
                    </div>
                    <i v-if="list2.length < 1" class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload> -->
                  <div class="list-img-box">
                        <div v-if="list2[0]" class="imgBox">
                          <img class="upload-img" :src="list2[0]" style='width:150px;height:150px' @click="uploadPicture('list2')" alt="身份证">
                          <div class="img-icon">
                            <i class="el-icon-zoom-in" @click.stop="onPreview(list2[0])"></i>
                            <i class="el-icon-delete" @click.stop="handelRemove(2,index)"></i>
                          </div>
                        </div>
                        <div v-else class="upload-btn" style="" @click="uploadPicture('list2')">
                          <i class="el-icon-plus" style="font-size: 30px;"></i>
                        </div>
                      </div>
                     <!-- <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess2"
                        >
                        <img v-if="form.idCardImgFront" :src="form.idCardImgFront" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        
                      </el-upload> -->
               </el-form-item>  
            </el-col>

            <el-col :span="7">
                <el-form-item label="身份证反面">
                  <!-- <el-upload
                      class="avatar-uploader xin"
                      :action="$uploadURL"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess6"
                      :before-upload="beforeAvatarUpload"
                    >
                    <div v-if="list6.length > 0" class="flex">
        <div
          style="margin: 5px"
          class="imgBox"
          v-for="(item, index) in list6"
          :key="index"
        >
          <img :src="item" class="avatar" alt="" />
          <div class="img-icon">
            <i
              class="el-icon-zoom-in"
              @click.stop="onPreview(item)"
            ></i>
            <i class="el-icon-delete" @click.stop="handelRemove(6,index)"></i>
          </div>
        </div>
                    </div>
                    <i v-if="list6.length < 1" class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload> -->
                  <div class="list-img-box">
                        <div v-if="list6[0]" class="imgBox">
                          <img class="upload-img" :src="list6[0]" style='width:150px;height:150px' @click="uploadPicture('list6')" alt="身份证反面">
                          <div class="img-icon">
                            <i class="el-icon-zoom-in" @click.stop="onPreview(list6[0])"></i>
                            <i class="el-icon-delete" @click.stop="handelRemove(6,index)"></i>
                          </div>
                        </div>
                        <div v-else class="upload-btn" style="" @click="uploadPicture('list6')">
                          <i class="el-icon-plus" style="font-size: 30px;"></i>
                        </div>
                      </div>
                    <!-- <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess6"
                        >
                        
                        <img v-if="form.idCardImgReverse" :src="form.idCardImgReverse" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload> -->
               </el-form-item>  
            </el-col>
            
            
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="7">
                <el-form-item label="营业执照">
                  <!-- <el-upload
                      class="avatar-uploader xin"
                      :action="$uploadURL"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess3"
                      :before-upload="beforeAvatarUpload"
                    >
                    <div v-if="list3.length > 0" class="flex">
        <div
          style="margin: 5px"
          class="imgBox"
          v-for="(item, index) in list3"
          :key="index"
        >
          <img :src="item" class="avatar" alt="" />
          <div class="img-icon">
            <i
              class="el-icon-zoom-in"
              @click.stop="onPreview(item)"
            ></i>
            <i class="el-icon-delete" @click.stop="handelRemove(3,index)"></i>
          </div>
        </div>
                    </div>
                    <i v-if="list3.length < 1" class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload> -->
                  <div class="list-img-box">
                        <div v-if="list3[0]" class="imgBox">
                          <img class="upload-img" :src="list3[0]" style='width:150px;height:150px' @click="uploadPicture('list3')" alt="营业执照">
                          <div class="img-icon">
                            <i class="el-icon-zoom-in" @click.stop="onPreview(list3[0])"></i>
                            <i class="el-icon-delete" @click.stop="handelRemove(3,index)"></i>
                          </div>
                        </div>
                        <div v-else class="upload-btn" style="" @click="uploadPicture('list3')">
                          <i class="el-icon-plus" style="font-size: 30px;"></i>
                        </div>
                      </div>
                      <!-- <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess3"
                        >
                        
                        <img v-if="form.licenceImg" :src="form.licenceImg" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload> -->
               </el-form-item>  
            </el-col>
            <el-col :span="7">
                <el-form-item label="店铺头图">
                  <!-- <el-upload
                      class="avatar-uploader xin"
                      :action="$uploadURL"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccesstt"
                      :before-upload="beforeAvatarUpload"
                    >
                    <div v-if="listtt.length > 0" class="flex">
        <div
          style="margin: 5px"
          class="imgBox"
          v-for="(item, index) in listtt"
          :key="index"
        >
          <img :src="item" class="avatar" alt="" />
          <div class="img-icon">
            <i
              class="el-icon-zoom-in"
              @click.stop="onPreview(item)"
            ></i>
            <i  class="el-icon-delete" @click.stop="handelRemove( 'tt', index)"></i>
          </div>
        </div>
                    </div>
                    <i v-if="listtt.length < 1" class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload> -->
                  <div class="list-img-box">
                        <div class="imgBox" v-if="listtt[0]">
                          <img class="upload-img" :src="listtt[0]" style='width:150px;height:150px' @click="uploadPicture('listtt')" alt="店铺头图">
                          <div class="img-icon">
                            <i class="el-icon-zoom-in" @click.stop="onPreview(listtt[0])"></i>
                            <i class="el-icon-delete" @click.stop="handelRemove('tt',index)"></i>
                          </div>
                        </div>
                        <div v-else class="upload-btn" style="" @click="uploadPicture('listtt')">
                          <i class="el-icon-plus" style="font-size: 30px;"></i>
                        </div>
                      </div>
                      <div style="font-size: 12px;padding-left: 5px;color: #60627f;">图片建议尺寸(750 × 343)</div>
                     <!-- <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccesstt"
                        >
                        <img v-if="form.headerUrl" :src="form.headerUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload> -->
               </el-form-item>  
            </el-col>
            <el-col :span="7">

            </el-col>
            
            
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="23">
                <el-form-item label="资质许可证">
                  <!-- <el-upload
                      class="avatar-uploader xin"
                      :action="$uploadURL"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess4"
                      :before-upload="beforeAvatarUpload"
                    >
                    <div v-if="list4.length > 0" class="flex">
        <div
          style="margin: 5px"
          class="imgBox"
          v-for="(item, index) in list4"
          :key="index"
        >
          <img :src="item" class="avatar" alt="" />
          <div class="img-icon">
            <i
              class="el-icon-zoom-in"
              @click.stop="onPreview(item)"
            ></i>
            <i class="el-icon-delete" @click.stop="handelRemove(4,index)"></i>
          </div>
        </div>
                    </div>
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload> -->
                  <div v-if="list4.length > 0" class="flex">
                    <div
                      class="imgBox"
                      v-for="(item, index) in list4"
                      :key="index"
                    >
                      <img :src="item" class="upload-img" alt="" />
                      <div class="img-icon">
                        <i class="el-icon-zoom-in" @click.stop="onPreview(item)"></i>
                        <i class="el-icon-delete" @click.stop="handelRemove(4,index)"></i>
                      </div>
                    </div>
                  </div>
                  <div class="list-img-box">
                    <div class="upload-btn" style="" @click="uploadPicture('list4')">
                      <i class="el-icon-plus" style="font-size: 30px;"></i>
                    </div>
                  </div>
                    <!-- <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess4"
                        >
                        
                        <img v-if="form.qualifications" :src="form.qualifications" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload> -->
               </el-form-item>  
            </el-col>

            
            
            
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="23">
                <el-form-item label="门头照">
                  <!-- <el-upload
                      class="avatar-uploader xin"
                      :action="$uploadURL"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccessmt"
                      :before-upload="beforeAvatarUpload"
                    >
                    <div v-if="listmt.length > 0" class="flex">
        <div
          style="margin: 5px"
          class="imgBox"
          v-for="(item, index) in listmt"
          :key="index"
        >
          <img :src="item" class="avatar" alt="" />
          <div class="img-icon">
            <i
              class="el-icon-zoom-in"
              @click.stop="onPreview(item)"
            ></i>
            <i class="el-icon-delete" @click.stop="handelRemove( 'mt', index)"></i>
          </div>
        </div>
                    </div>
                    <i  class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload> -->
                  <div v-if="listmt.length > 0" class="flex">
                    <div
                      class="imgBox"
                      v-for="(item, index) in listmt"
                      :key="index"
                    >
                      <img :src="item" class="upload-img" alt="" />
                      <div class="img-icon">
                        <i class="el-icon-zoom-in" @click.stop="onPreview(item)"></i>
                        <i class="el-icon-delete" @click.stop="handelRemove('mt',index)"></i>
                      </div>
                    </div>
                  </div>
                  <div class="list-img-box">
                    <div class="upload-btn" style="" @click="uploadPicture('listmt')">
                      <i class="el-icon-plus" style="font-size: 30px;"></i>
                    </div>
                  </div>
                     <!-- <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccessmt"
                        >
                        <img v-if="form.headImg" :src="form.headImg" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload> -->
               </el-form-item>  
            </el-col>

          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="23">
                <el-form-item label="环境照">
                  <!-- <el-upload
                      class="avatar-uploader xin"
                      :action="$uploadURL"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess5"
                      :before-upload="beforeAvatarUpload"
                    >
                    <div v-if="list5.length > 0" class="flex">
        <div
          style="margin: 5px"
          class="imgBox"
          v-for="(item, index) in list5"
          :key="index"
        >
          <img :src="item" class="avatar" alt="" />
          <div class="img-icon">
            <i
              class="el-icon-zoom-in"
              @click.stop="onPreview(item)"
            ></i>
            <i class="el-icon-delete" @click.stop="handelRemove(5,index)"></i>
          </div>
        </div>
                    </div>
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload> -->
                  <div v-if="list5.length > 0" class="flex">
                    <div
                      class="imgBox"
                      v-for="(item, index) in list5"
                      :key="index"
                    >
                      <img :src="item" class="upload-img" alt="" />
                      <div class="img-icon">
                        <i class="el-icon-zoom-in" @click.stop="onPreview(item)"></i>
                        <i class="el-icon-delete" @click.stop="handelRemove(5,index)"></i>
                      </div>
                    </div>
                  </div>
                  <div class="list-img-box">
                    <div class="upload-btn" style="" @click="uploadPicture('list5')">
                      <i class="el-icon-plus" style="font-size: 30px;"></i>
                    </div>
                  </div>
                      <!-- <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess5"
                        >
                        
                        <img v-if="form.environmentImg" :src="form.environmentImg" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload> -->
               </el-form-item>  
            </el-col>

          </el-row>
          
        
           <el-form-item style="text-align: center;">
             <el-button style="width: 140px;height: 50px;" @click="$router.push({path:'/auditBusinesstwo',query:{'pageNum':pageNum}})">取消</el-button>
             <el-button style="width: 140px;height: 50px;" type="primary" @click="onSubmit">保存</el-button>
             
           </el-form-item>
        </el-form>
        <!-- 剪裁组件弹窗 -->
        <el-dialog
            title="裁切图片"
            :visible.sync="cropperModel"
            width="1200px"
            center
          >
        <cropper-image
          v-if="cropperModel"
          :Name="cropperName" :New="cropperModel" :setfixed="setfixed"
          :AutoCropWidth="AutoCropWidth" :AutoCropHeight="AutoCropHeight" :FixedNumber="FixedNumber"
          @uploadImgSuccess = "handleUploadSuccess"
          ref="child">
        </cropper-image>
        </el-dialog>
        <!--查看大封面-->
        <el-dialog
            title="查看图片"
            :visible.sync="imgVisible"
            class="previewDialog"
            center>
          <img :src="oldpreview" v-if="imgVisible" style="width: 100%" alt="查看">
        </el-dialog>
    </div>
  </template>
  
  <script>
  import CropperImage from "@/components/CropperImage.vue";
 export default {
  components:{
    CropperImage
  },
  data() {
    return {
      list2:[],
      list6:[],
      list4:[],
      list1:[],
      list5:[],
      list3:[],
      listmt:[],
      listtt:[],
        form: {
          isChain:0,
          logoUrl: '',
          idCardImgFront:'',
          idCardImgReverse:'',
          licenceImg:'',
          qualifications:'',
          environmentImg:'',
          headImg:'',
          headerUrl:''
          
        },
        fileList:[],
        uploadURL :'http://113.44.32.145/api/upload/uploadFile',
        disabled:false,
      
        valuechange:'',
        valuechange1:'',
        options:[],
        pageNum: null,
        //裁切图片参数
        cropperModel:false,
        cropperName:'',
        AutoCropWidth: null,//宽
        AutoCropHeight: null,//高
        FixedNumber: null,//比例
        setfixed: true,//比例固定
        oldpreview: '',//预览图
        imgVisible: false

    };
  },
  mounted() {
    if(this.$route.query.id){
      
      this.id = this.$route.query.id
      this.pageNum = this.$route.query.pageNum
      this.$http.post('/business/queryBusinessDetail',{
        id:this.id
      }).then(response => {
         this.form = response.data.data;
         this.form.isAdmin = 2
        //  this.valuechange =  ['09:00:00', '13:30:00']
        if(response.data.data.businessStartTime &&response.data.data.businessEndTime) {
          this.valuechange =[response.data.data.businessStartTime,response.data.data.businessEndTime]
         }
        
         if(response.data.data.businessStartTimePm &&response.data.data.businessEndTimePm) {
          this.valuechange1 =[response.data.data.businessStartTimePm,response.data.data.businessEndTimePm]
         }
         
         console.log(this.valuechange,'sssss');

         this.list2 = response.data.data.idCardImgFront.split(",");
         this.list6 = response.data.data.idCardImgReverse.split(",");
         this.list4 = response.data.data.qualifications.split(",");
         this.list1 = response.data.data.logoUrl.split(",");
         this.list5 = response.data.data.environmentImg.split(",");
         this.list3 = response.data.data.licenceImg.split(",");
         this.listmt = response.data.data.headImg.split(",");
         this.listtt = response.data.data.headerUrl.split(",");
         
         
         
         
         
      })
    }
    var that = this;
    that.$http.post("/area/queryList", {}).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          // that.total = response.data.data.count;
          // that.queryInfo.currPage = response.data.data.currPage
          that.options = response.data.data;
        }
      });
  },
  methods: {
    handleSuccess(response, file, fileList){
      console.log( response.data.url);
    },
    onSubmit(){
        
        
        var that = this;
        that.form.isChain = 1
       
       

        that.form.idCardImgFront = that.list2.join(',')
        that.form.idCardImgReverse = that.list6.join(',')
        that.form.qualifications = that.list4.join(',')
        that.form.logoUrl = that.list1.join(',')
        that.form.environmentImg = that.list5.join(',')
        that.form.licenceImg = that.list3.join(',')
        that.form.headImg = that.listmt.join(',')
        that.form.headerUrl = that.listtt.join(',')




        
        that.$http.post("/business/saveBusiness", that.form).then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.$router.push({path:'/auditBusinesstwo',query:{pageNum:that.pageNum}})
          } else {
           that.$message({
            message: response.data.message,
            type: 'error'
           })
          }
        });
    },
    //图片裁剪设置
    uploadPicture(name){
      console.log('???',name)
      this.cropperName = name;
      this.setfixed = true
      switch(name){
        case 'listtt':
          this.AutoCropWidth = 375//宽
          this.AutoCropHeight = 171.5//高
          this.FixedNumber = [750,343]//比例
          break;
        case 'list1':
          this.AutoCropWidth = 150//宽
          this.AutoCropHeight = 150//高
          this.FixedNumber = [1,1]//比例
          break;
        case 'list2':
        case 'list6':
          this.AutoCropWidth = 171.2//宽
          this.AutoCropHeight = 108//高
          this.FixedNumber = [171.2,108]//比例
          break;
        case 'list3':
        this.setfixed = false
          this.AutoCropWidth = 200//宽
          this.AutoCropHeight = 150//高
          this.FixedNumber = [200,150]//比例
          break;
        case 'list4':
        case 'listmt':
        case 'list5':
        this.setfixed = false
          this.AutoCropWidth = 200//宽
          this.AutoCropHeight = 150//高
          this.FixedNumber = [200,150]//比例
          break;
      }
      this.cropperModel = true;
    },
    //图片上传成功后
    handleUploadSuccess (data){
      console.log(data)
      switch(this.cropperName){
        case 'listtt':
          this.listtt = []
          this.listtt.push(data.url)
          console.log('最终输出'+this.cropperName)
          break;
        case 'list1':
          this.list1 = []
          this.list1.push(data.url)
          console.log('最终输出'+this.cropperName)
          break;
        case 'list2':
          this.list2 = []
          this.list2.push(data.url)
          console.log('最终输出'+this.cropperName)
          break;
        case 'list6':
          this.list6 = []
          this.list6.push(data.url)
          console.log('最终输出'+this.cropperName)
          break;
        case 'list3':
          this.list3 = []
          this.list3.push(data.url)
          console.log('最终输出'+this.cropperName)
          break;
        case 'list4':
          this.list4.push(data.url)
          console.log('最终输出'+this.cropperName)
          break;
        case 'list5':
          this.list5.push(data.url)
          console.log('最终输出'+this.cropperName)
          break;
        case 'listmt':
          this.listmt.push(data.url)
          console.log('最终输出'+this.cropperName)
          break;
      }
      this.cropperName = ''
      this.oldpreview = ''
      this.cropperModel = false;
    },
    //图片预览
    onPreview(url){
      this.oldpreview = url
      this.imgVisible = true;
    },
    change(){
      console.log(this.valuechange );
      // console.log(this.valuechange,this.newDate(this.valuechange[0]) );
      this.form.businessStartTime =this.valuechange[0]
      this.form.businessEndTime = this.valuechange[1]
      console.log(this.form,'this.from');
      
    },
    change1(){
      console.log(this.valuechange );
      // console.log(this.valuechange,this.newDate(this.valuechange[0]) );
      this.form.businessStartTimePm =this.valuechange1[0]
      this.form.businessEndTimePm = this.valuechange1[1]
      console.log(this.form,'this.from');
      
    },
    newDate(time){
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return h + ':' + minute + ':' + s
    },
    handleAvatarSuccess1(res, file) {
      console.log(file.response.data.url,'88888888');
        // this.form.logoUrl = file.response.data.url;
        this.list1.push(file.response.data.url);
        
    },
    handleAvatarSuccess2(res, file) {
      console.log(file.response.data.url,'88888888');
        // this.form.idCardImgFront = file.response.data.url;
        this.list2.push(file.response.data.url);
    },
    handleAvatarSuccess3(res, file) {
      console.log(file.response.data.url,'88888888');
        // this.form.licenceImg = file.response.data.url;
        this.list3.push(file.response.data.url);

    },
    handleAvatarSuccess4(res, file) {
      console.log(file.response.data.url,'88888888');
        // this.form.qualifications = file.response.data.url;
        this.list4.push(file.response.data.url);
    },
    handleAvatarSuccess5(res, file) {
      console.log(file.response.data.url,'88888888');
     
        // this.form.environmentImg = file.response.data.url;
        this.list5.push(file.response.data.url);
    },
    handleAvatarSuccess6(res, file) {
      console.log(file.response.data.url,'88888888');
        // this.form.idCardImgReverse = file.response.data.url;
        this.list6.push(file.response.data.url);
    },
    handleAvatarSuccessmt(res, file) {
      console.log(file.response.data.url,'88888888');
        // this.form.headImg = file.response.data.url;
        this.listmt.push(file.response.data.url);
        
    },
    handleAvatarSuccesstt(res, file) {
      console.log(file.response.data.url,'88888888');
        // this.form.headerUrl = file.response.data.url;
        this.listtt.push(file.response.data.url);
        
    },
    handelRemove(num,i){
      console.log(i);
      if(num == 1) {
        this.list1.splice(i, 1,)
      } 
      if(num == 2) {
        this.list2.splice(i, 1,)
      } 
      if(num == 3) {
        this.list3.splice(i, 1,)
      } 
      if(num == 4) {
        this.list4.splice(i, 1,)
      } 
      if(num == 5) {
        this.list5.splice(i, 1,)
      } 
      if(num == 6) {
        this.list6.splice(i, 1,)
      } 
      if(num == 'mt') {
        this.listmt.splice(i, 1,)
      } 
      if(num == 'tt') {
        this.listtt.splice(i, 1,)
      } 
      
      
    },
    
  }
};
  </script>
  
  <style lang="less" scoped>
  .box {
    background-color: #fff;
    // height: 100vh;
    padding: 20px 50px;
  }
  
  /deep/ .avatar-uploader .el-upload {
    // border: 1px dashed #877b7b;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }
  .avatar {
    width: 108px;
    height: 108px;
    // display: block;
  }
  .fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding-top: 20px;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 2;
}
.container {
  padding: 5px;
  box-sizing: border-box;
  .box {
    width: 100%;

    background-color: #fff;
    font-size: 14px;
    border-radius: 5px;
    color: #333;
    .title {
      border-bottom: 1px solid #eee;
      padding: 10px;
      box-sizing: border-box;
      &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 20px;
        margin-right: 15px;
        background-color: #02a7f0;
        // 和标题对其
        vertical-align: middle;
      }
    }
    .content2 {
      padding: 0px 20px 20px 20px;
      box-sizing: border-box;
    }
    .content {
      padding: 0px 20px;
      box-sizing: border-box;

      .imgTitle {
        margin-top: 10px;
        box-sizing: border-box;
        font-weight: 700;
        padding-bottom: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #eee;
      }
      .imgbox {
        padding: 10px;
        box-sizing: border-box;
      }

      .fa {
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding-left: 15px;
        box-sizing: border-box;
        background-color: #e3f5fd;
        margin: 10px;
      }
    }
  }
}

.mp {
  margin-top: 15px;
}
.mp10 {
  margin-top: 10px;
}
.pd50 {
  padding: 0 50px;
  box-sizing: border-box;
}
.left10 {
  padding-left: 20px;
  box-sizing: border-box;
}
.tp5 {
  margin-top: 5px;
  width: 148px;
  text-align: center;
  font-weight: 700;
  color: #8c939d;
}
.imgs {
  width: 130px;
  height: 130px;
  margin: 5px;
}
.center {
  width: 600px;
  text-align: center;
  margin: 10px auto;
}

.lable {
  width: 130px;
  display: inline-block;
  text-align: end;
}

.imgBox {
  //width: 148px;
  height: 148px;
  height: 148px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.imgBox:hover .img-icon {
  display: flex;
}

.img-icon {
  width: 148px;
  height: 148px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.4);

  i {
    font-size: 26px;
    color: #fff;
  }
}

.avatar-uploader {
  width: 100%;
}

.avatar-uploader ::v-deep .el-upload {
  //background-color: #fbfdff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  border: 1px dashed #c0ccda;
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

/deep/.el-loading-spinner {
  margin-top: -74px;
}

.avatar {
  display: block;
  width: 148px;
  height: 148px;
  border-radius: 6px;
}

.imgBox {
  width: 148px;
  height: 148px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.table {
  .el-button {
    height: 20px !important;
    line-height: 6px !important;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}
.text {
  text-align: center;
  margin-top: 10px;
}
::v-deep .el-table td {
  padding: 0 !important;
}
::v-deep .el-table th {
  padding: 0px !important;
}
.pdfbox {
  display: flex;

  .item {
    margin: 0 10px;
  }
}
.imgboxs {
  margin-left: 130px;
  margin-top: 5px;
  display: flex;
  & > div {
    margin: 0 10px;
  }
}
::v-deep .el-image {
  margin: 5px !important;
}

// .titlesss::before {
//   content: "*";
//   color: red;
//   position: absolute;
//   left: 2px;
// }
.titlesss {
  color: red;
  display: inline-block;
  margin-right: 3px;
}

.success {
  color: #62a003;
  font-weight: 700;
}

.error {
  color: #d6000a;
  font-weight: 700;
}
.shenhebox {
  height: 500px;
  overflow: auto;
}
.tag {
  width: 80px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
}
.list-img-box{
  width: 150px;
  height: 150px;
  border-radius: 6px;
  border: 1px dashed #c0ccda;
  color: #8c939d;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .upload-btn{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.upload-img{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
  </style>
  